<template>
	<div class="news-item">
		<div class="wrapper">
			<div class="news-item_content __item">
				<h1 class="news-item_title __left">
					{{ news.title }}
				</h1>
				<p>
					{{ getTime(news.pub_date) }}
				</p>
				<div v-html="news.content"></div>
			</div>
			<br>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	
	export default {
		data() {
			return {
				news: {}
			}
		},
		
		created() {
			this.axios.get('/api/news/' + this.$route.params.id)
				.then(({data}) => {
					this.news = data;
				});
		},
		
		methods: {
			getTime(date) {
				return moment(date).locale('ru').format('D MMMM Y, HH:mm')
			}
		}
	}
</script>