<template>
	<div class="block __p __bg">
		<div class="wrapper">
			<header class="block_header">
				<div class="block_title">СТРИМЫ</div>
			</header>
			
			<div class="streams">
				<streamers v-if="users" :users="users" :streams="streams"></streamers>
				<div class="loading" v-else-if="loading"></div>
				<div v-else class="text-block">В данный момент онлайн трансляций нет</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Streamers from "../components/Twitch/Streamers";
	
	export default {
		components: {
			Streamers
		},
		
		data() {
			return {
				streams: null,
				users: null,
				loading: true
			}
		},
		
		created() {
			this.axios.get('/links').then(({data}) => {
				this.streams = data.res ? data.res.data : null;
				
				this.getStreamersInfo();
			});
		},
		
		methods: {
			getStreamersInfo() {
				let users = '';
				
				this.streams.forEach((item) => {
					users += 'user_login=' + item.url + '&';
				});

				this.axios.get('https://api.twitch.tv/helix/streams?' + users, twitchApi).then(({data}) => {
					this.users = data.data;
					this.loading = false;
				});
			}
		}
	}
</script>

<style scoped>

</style>