<template>
	<div>
		<div class="stream">
			<div class="wrapper">
				<div class="stream_row">
					<div class="stream_video">
						<div id="twitch-embed"></div>
						
						<div class="streams_content" v-if="user">
							<div class="streams_ico">
								<img :src="user.profile_image_url" alt="">
							</div>
							<div class="streams_data">
								<div class="streams_title">{{ user.display_name }}</div>
								<div class="streams_text">{{ user.login }}</div>
							</div>
						</div>
						
						<h3 class="stream_heading" v-if="user && user.description">Информация: {{ user.login }}</h3>
						<p class="stream_description" v-if="user && user.description">{{ user.description }}</p>
					</div>
					
					<div class="stream_chat" v-if="user">
						<iframe id="chat_embed" :src="'https://www.twitch.tv/embed/' + user.login + '/chat?parent=' + hostname" height="400" width="350"></iframe>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				user: null,
				channel: this.$route.params.id,
				hostname: location.hostname
			}
		},
		
		created() {
			this.axios.get('https://api.twitch.tv/helix/users?id=' + this.channel, twitchApi).then(({data}) => {
				this.user = data.data[0];

				new Twitch.Player("twitch-embed", {
					channel: this.user.login
				});
			});
		}
	}
</script>

<style scoped lang="less">
	#twitch-embed {
		margin-bottom: 20px;
	}
	.stream_heading {
		margin-top: 30px;
		font-size: 16px;
	}
	.stream_description {
		margin-top: 10px;
		font-size: 14px;
		line-height: 1.5;
	}
	.stream_row {
		display: flex;
		
		@media (max-width: 767px) {
			display: block;
		}
	}
	.stream_video {
		width: 60%;
		
		@media (max-width: 767px) {
			margin-bottom: 20px;
			width: auto;
		}
	}
	.stream_chat {
		width: 40%;
		
		@media (max-width: 767px) {
			width: auto;
		}
		
		iframe {
			height: 500px;
		}
	}
</style>