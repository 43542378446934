<template>
	<div>
		<tab v-if="!loading" :headings="{all: 'Все', live: 'LIVE', finished: 'Завершенные'}" active-key="all">
			<template v-slot:all>
				<links :day-diff="day" v-if="matches" :data="matches"></links>
				<div class="info" v-else>Нет матчей</div>
			</template>
			<template v-slot:live>
				<links :day-diff="day" v-if="liveMatches && Object.keys(liveMatches).length" :data="liveMatches"></links>
				<div class="info" v-else>Нет матчей в прямом эфире</div>
			</template>
			<template v-slot:finished>
				<links :day-diff="day" v-if="finishedMatches && Object.keys(finishedMatches).length" :data="finishedMatches"></links>
				<div class="info" v-else>Нет завершенных матчей</div>
			</template>
			
			<template v-slot:custom-heading>
				<li class="res-tab_header-item">
					<a href="" @click.prevent="activeDateDropdown = !activeDateDropdown">Календарь</a>
					
					<div class="res-tab_header-dropdown" v-if="activeDateDropdown">
						<a :class="{active: day.key == activeDay.key}" @click.prevent="update(day.key)" href="" v-for="day in days" v-text="day.value" :key="day.key"></a>
					</div>
				</li>
			</template>
		</tab>
		<div class="loading" v-else-if="loading">Загрузка</div>
	</div>
</template>

<script>
	import moment from  'moment'
	import Tab from "../Tab/Tab";
	import Links from "./Links";
	import _ from 'underscore';
	
	export default {
		components: {
			Tab,
			Links
		},

		props: ['data', 'loading'],
		
		created() {
			window.events.$emit('activeDay', this.activeDay);
		},
		
		data() {
			return {
				day: this.$route.query.day_diff || 0,
				activeDateDropdown: false,
			}
		},

		computed: {
			matches() {
				if (!this.data) return null;

				return _.groupBy(this.data, 'league');
			},
			liveMatches() {
				if (!this.data) return null;

				let data = {};

				_.each(this.matches, (league, key) => {
					data[key] = _.filter(league, (match) => {
						return match.timer;
					});
				});

				return _.pick(data, league => league.length);
			},
			finishedMatches() {
				if (!this.data) return null;

				let data = {};

				_.each(this.matches, (league, key) => {
					data[key] = _.filter(league, (match) => {
						return match.status === 'Finished';
					});
				});

				return _.pick(data, league => league.length);
			},
			days() {
				let data = [
					{
						key: '0',
						value: 'Сегодня',
						defaultValue: 'сегодня',
					}
				];

				for (let i = 1; i <= 7; i++) {
					data.unshift({
						key: '-' + i,
						value: moment().subtract(i, 'days').locale('ru').format('DD.MM ddd'),
						defaultValue: moment().subtract(i, 'days').locale('ru').format('DD MMMM Y')
					});
					data.push({
						key: i,
						value: moment().add(i, 'days').locale('ru').format('DD.MM ddd'),
						defaultValue: moment().add(i, 'days').locale('ru').format('DD MMMM Y')
					});
				}

				return data;
			},
			activeDay() {
				let day =  this.days.filter((day) => {
					return day.key == this.day;
				})[0];

				return day;
			}
		},

		methods: {
			update(day) {
				this.$emit('update', day);
			}
		},
	}
</script>

<style scoped>

</style>