<template>
	<div>
		<div class="coaching">
			<div class="wrapper">
				<div class="coaching_logo">
					<img src="/img/upskill_logo.png" alt="">
				</div>
				
				<h1 class="coaching_title">Обучение и тренировки по киберспорту</h1>
				
				<div class="coaching_button __right" v-if="!auth.subscribed">
					<router-link :to="{name: 'subscribe'}">Оформить подписку</router-link>
				</div>
				
				<div class="coaching_title-2">Почему коучинг?</div>
				
				
				<div class="coaching_text">
					<p>Мы живем в мире с избытком информации вокруг нас. Бывает крайне сложно отфильтровать лишнее и найти то, что подойдет именно тебе на 100%.</p>
					
					<p>Многие думают, что научатся играть, смотря популярных стимеров или видео на youtube. Но их основная задача - это развлекать и удерживать ваше внимание.</p>
					
					<p>Самый эффективный способ тренировок - занятия с коучем. Наш опыт и отзывы тысяч игроков - лучшее тому подтверждение.</p>
					
					<img src="/img/reviews.jpg" alt="">
					
					<p>Если ты хочешь попасть в киберспорт, найти команду обыграть знакомого или просто стать лучше- подписка на Upskill точно не будет ошибкой!</p>
					
					<p>
						Не важно какой у тебя рейтинг или звание! <br>
						У нас ты можешь найти подходящего тренера и научиться новому!
					</p>
				</div>
				
				<div class="coaching_title-2">Как работает?</div>
				
				<div class="coaching_text">
					<p>
						1. Выбери игру <br>
						2. Оплати подписку <br>
						3. Получайте доступ к закрытым вебинарам с про-игроками и тренерами Upskill
					</p>
					
					<p>Кроме вебинаров мы планируем добавлять уникальный обучающий контент для подписчиков и другие бонусы, которые станут доступны в ближайшее время.</p>
				</div>
				
				<div class="coaching_title-2">Что я получу?</div>
				
				<div class="coaching_text">
					<p>Оформи подписку сейчас и получи:</p>
					
					<p>
						- нереальный буст скилла уже после первой лекции! <br>
						- доступ к уникальным вебинарам и материалам от наших тренеров и известных топ-игроков;<br>
						- ежемесячные обновления в соответствии с метой и актуальными патчами;<br>
						- персональный промокод со скидкой на все индивидуальные тренировки;<br>
						- приглашение в закрытый Discord-канал с нашими тренерами и другими учениками и многое другое!
					</p>
				</div>
				
				<div class="coaching_text-2">
					Только сейчас действует скидка 50% для ранних подписчиков! <br>
					Успейте записаться до повышения цен!
				</div>
				
				<div class="coaching_button" v-if="!auth.subscribed">
					<router-link :to="{name: 'subscribe'}">Оформить подписку</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				auth: window.auth
			}
		}
	}
</script>