<template>
	<ul :class="className">
		<!--<li><router-link :to="{name: 'coaching'}">Коучинг</router-link></li>-->
		<li><router-link :to="{name: 'news', query: {sport: 'cyber'}}">Новости</router-link></li>
		<li><router-link :to="{name: 'articles', query: {sport: 'cyber'}}">Статьи</router-link></li>
		<li><router-link :to="{name: 'live', params: {sport: 'CS GO'}}">Матчи</router-link></li>
		<li><router-link :to="{name: 'streams'}">Стримы</router-link></li>
	</ul>
</template>

<script>
	export default {
		props: ['className']
	}
</script>

<style scoped>

</style>