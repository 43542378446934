<template>
	<div class="match_items">
		<div class="match_item">
			<div class="match_item-content" v-for="(group, groupName) in matches" :key="groupName">
				<div>
					<div class="match_item-header active">
						<span v-text="groupName"></span>
						<i class="ico icon-side-arrow"></i>
					</div>
					<div class="match_item-data active">
						<div class="match-data">
							<div v-if="sport === 'CS GO'">
								<router-link  v-for="match in group" class="match-data_item" :to="getLink(match)" :key="'link' + match.id">
									<link-base :show-date="showDate" :match="match"></link-base>
								</router-link>
							</div>
							
							<div v-else>
								<div v-for="match in group" class="match-data_item" :key="'item' + match.id">
									<link-base :show-date="showDate" :match="match"></link-base>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import LinkBase from "./Link";
	
	export default {
		components: {
			LinkBase
		},
		
		props: ['data', 'showDate', 'hidePrefix', 'dayDiff'],
		
		data() {
			return {
				sport: this.$route.params.sport
			}
		},
		
		computed: {
			matches() {
				return this.data;
			},
		},
		
		methods: {
			getTitle(prefix, suffix) {
				let data = {
					'Матчи': 'Матчи',
				};
				
				let pref = '';
				
				if (!this.hidePrefix) {
					pref = data[prefix] ? data[prefix]: prefix ? prefix: '';
				}
				suffix = data[suffix] ? data[suffix] : suffix ? suffix : '';
				
				return pref + ' ' + suffix;
			},
			getLink(match) {
				let query = {};
				
				if (this.dayDiff || this.dayDiff == 0) {
					query.day_diff = this.dayDiff;
				}
				return {
					name: 'match', 
					params: {
						id: match.id, 
						sport: match.type
					},
					query: query
				}
			}
		}
	}
</script>

<style scoped>

</style>