<template>
	<div class="news">
		<div class="news_items">
			<router-link :to="{name: 'news-item', params: {id: newsItem.id}}" class="news_item" v-for="(newsItem, index) in news" :key="newsItem.id">
				<div class="news_row">
					<div class="news_image">
						<img v-if="index % 2 === 0" src="/img/lol.svg" alt="">
						<img v-else-if="index % 3 === 0" src="/img/222.png" alt="">
						<img v-else src="/img/333.png" alt="">
					</div>
					<div class="news_content">
						<div class="news_time">
							{{ getTime(newsItem.pub_date) }}
						</div>
						<div class="news_title">
							{{ newsItem.title }}
						</div>
					</div>
				</div>
			</router-link>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	
	export default {
		props: ['data'],
		
		computed: {
			news() {
				return this.data;
			}
		},
		methods: {
			getTime(date) {
				return moment(date).locale('ru').format('HH:mm');
			},
		}
	}
</script>

<style scoped>

</style>