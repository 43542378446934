<template>
	<div>
		<div class="block __p">
			<div class="wrapper">
				<header class="block_header">
					<div class="block_title">СТАТЬИ</div>
				</header>
				
				<base-articles :data="items" />
				
				<paginate v-if="count" @page-changed="updateData" :total="count" :limit="limit"></paginate>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	
	import BaseArticles from "../components/Articles/BaseArticles";
	import SportCategories from "../components/Articles/SportCategories";
	import Paginate from "../components/Pagination/Paginate";
	
	export default {
		components: {
			BaseArticles,
			SportCategories,
			Paginate
		},
		
		data() {
			return {
				sport: this.$route.query.sport,
				page: Number(this.$route.query.page) || 1,
				items: [],
				limit: 9,
				count: 0,
				gotData: false
			}
		},
		
		mounted() {
			
		},
		
		created() {
			this.initArticles();
		},
		
		
		methods: {
			initArticles() {
				let params = {
					sport: 'cyber',
					limit: this.limit,
					page: this.page
				};
				
				this.axios.get('/api/articles', {params: params})
					.then(({data}) => {
						this.items = data.data;
						this.count = data.count;
						this.gotData = true;
					});
			},
			getTime(time) {
				return moment(time).locale('ru').format('D MMMM Y, HH:mm')
			},
			
			updateData(page) {
				this.page = page;
				
				this.$router.push({
					name: 'articles',
					query: {
						page: page,
						sport: this.sport
					}
				});
			},
			
			updateSport(sport) {
				this.sport = sport;
				
				this.updateData(1);
			}
		}
	}
</script>

<style scoped>

</style>