<template>
	<div class="matches">
		<div class="wrapper">
			<div class="h1-like">События {{ activeDay.defaultValue }}</div>
			
			<select class="matches_select" v-model="sport" @change="changeSport">
				<option>CS GO</option>
				<option>FIFA</option>
				<option>CyberTennis</option>
				<option>NHL20</option>
				<option>DOTA2</option>
				<option>NBA2K</option>
			</select>
			
			<list :data="data" @update="update" :loading="loading"></list>
		</div>
	</div>
</template>

<script>
	import List from "../components/Events/List";
	
	export default {
		components: {
			List
		},
		
		data() {
			return {
				sport: this.$route.params.sport,
				data: null,
				loading: true,
				activeDay: {}
			}
		},
		
		created() {
			this.initData();
			
			window.events.$on('activeDay', (day) => {
				this.activeDay = day;
			});
		},
		
		methods: {
			initData() {
				let day = this.$route.query.day_diff || 0;
				let url = '/goalserve/esport/events?type=' + this.sport;

				if (day !== 0) {
					url += '&day_diff=' + day;
				}

				this.axios.get(url)
					.then(({data}) => {
						this.data = data.res;
						this.loading = false;
					})
					.catch(()=> {
						this.loading = false;
					})
			},

			changeSport() {
				this.update();
			},
			update(day = 0) {
				this.$router.push({
					name: 'live',
					params: {
						sport: this.sport
					},
					query: {
						day_diff: day
					}
				});
			}
		}
	}
</script>

<style scoped>

</style>