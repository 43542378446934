<template>
	<header class="header">
		<div class="header_row">
			<div class="wrapper">
				<router-link to="/" class="header_logo">
					<img src="/img/logo_tcell.jpg" alt="">
					<span>КИБЕРПОРТАЛ</span>
				</router-link>
				<div class="header_btn"><i></i><i></i><i></i></div>
				
				<menu-list class-name="header_menu-list"></menu-list>
			</div>
		</div>
		
		<div class="header_menu">
			<div class="header_menu-top">
				<div class="header_menu-close"></div>
			</div>
			<menu-list class-name="header-nav"></menu-list>
		</div>
		
		<auth v-if="showForm" @hide="showForm = false"></auth>
	</header>
</template>

<script>
	import MenuList from "./MenuList";
	import Auth from "../Auth/Auth";
	
	export default {
		components: {
			MenuList,
			Auth
		},

		data() {
			return {
				showForm: false,
			}
		},
		
		created() {
			window.events.$on('not-logged', () => {
				this.showForm = true;
			});
		}
	}
</script>

<style scoped>

</style>
