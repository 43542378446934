<template>
	<div class="nav_list">
		<a :href="title" @click.prevent="change(title)" :class="{'router-link-active' : sportValue === title}" v-for="(item, title) in items" :key="title">
			{{ item }}
		</a>
	</div>
</template>

<script>
	export default {
		props: ['sport'],
		
		data() {
			return {
				items: {
					'cyber': 'Все',
					'CS GO': 'CS GO',
					'FIFA': 'FIFA',
					'CyberTennis': 'CyberTennis',
					'NHL20': 'NHL20',
					'DOTA2': 'DOTA2',
					'NBA2K': 'NBA2K',
				},
				sportValue: this.sport || ''
			}
		},
		methods: {
			change(value) {
				this.$emit('update', value);
			}
		},
	}
</script>

<style scoped>

</style>