import Vue from 'vue';
import VueRouter from 'vue-router';

import News from '../views/News.vue';
import NewsItem from '../views/NewsItem.vue';
import Home from '../views/Home.vue';
import Article from '../views/Article.vue';
import Articles from '../views/Articles.vue';
import Videos from '../views/Videos.vue';
import Video from '../views/Video.vue';
import Live from '../views/Live.vue';
import Match from '../views/Match.vue';
import Streams from '../views/Streams.vue';
import Stream from '../views/Stream.vue';
import Coaching from '../views/Coaching';
import CoachingSubscribe from '../views/CoachingSubscribe';
import CoachingCourses from '../views/CoachingCourses';
import Subscribe from "../views/Subscribe"
import NoMoney from "../views/NoMoney"

Vue.use(VueRouter);

const routes = [
	// News
	{path: '/news', name: 'news', component: News},
	{path: '/news/:id', name: 'news-item', component: NewsItem},
	
	// Articles
	{path: '/articles', name: 'articles', component: Articles},
	{path: '/articles/:id', name: 'article', component: Article},
	
	// Video
	//{path: '/videos', name: 'videos', component: Videos},
	//{path: '/videos/:id', name: 'video', component: Video},
	
	// Live
	{path: '/live/:sport', name: 'live', component: Live},
	
	// Match
	{path: '/match/:sport/:id', name: 'match', component: Match},
	
	// Streams
	{path: '/streams', name: 'streams', component: Streams},
	{path: '/streams/:id', name: 'stream', component: Stream},
	
	// Coaching
	{path: '/coaching', name: 'coaching', component: Coaching},
	{path: '/coaching/subscribe', name: 'coaching-subscribe', component: CoachingSubscribe},
	{path: '/coaching/courses', name: 'coaching-courses', component: CoachingCourses},

	// Subscribe
	{ path: '/subscribe', name: 'subscribe', component: Subscribe },
	{ path: '/no-money', name: 'no-money', component: NoMoney },
	
	// Home
	{path: '/', name: 'home', component: Home},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {
	if (Vue.config.local) {
		fake(to, from, next);
		return;
	}

	if (to.name === 'logout' || to.name === 'home') {
		next(); return;
	}

	window.axios({url: '/a1/profile', baseURL: '/'}).then(({data}) => {
		if(!data.msisdn) {
			if (to.name === 'home') {
				next(); return;
			} else {
				window.events.$emit('not-logged');
			}
		} else {
			window.events.$emit('auth');
			window.auth.auth = true;

			if (data.status === 'not_subscribed') {
				if (to.name === 'subscribe' || to.name === 'home') {
					next(); return;
				} else {
					location.href = '/subscribe';return;
				}
			} else if (data.status === 'subscribed' || data.status === 'allowed') {
				window.auth.subscribed = true;
				window.events.$emit('subscribed');

				if (to.name === 'subscribe') {
					location.href = '/'; return;
				} else {
					next(); return;
				}
			} else if (data.status === 'restricted') {
				if (to.name === 'restricted' || to.name === 'home') {
					next(); return;
				} else {
					location.href = '/restricted'; return;
				}
			}
		}

		if (to.name === 'home-base') {
			next();
		}
	});
});

function fake(to, from, next) {
	var data = {
		msisdn: 'ghg',
		status: 'subscribed'
	};

	if(!data.msisdn) {
		if (to.name === 'home') {
			next(); return;
		} else {
			window.events.$emit('not-logged');
		}
	} else {
		if (to.name === 'home') {
			if (data.status === 'subscribed' || data.status === 'allowed') {
				window.events.$emit('subscribed');
				window.auth.subscribed = true;
			}
			next();return;
		}
		
		window.auth.auth = true;
		window.events.$emit('auth');

		if (data.status === 'not_subscribed') {
			if (to.name === 'subscribe' || to.name === 'home') {
				next(); return;
			} else {
				location.href = '/subscribe';return;
			}
		} else if (data.status === 'subscribed' || data.status === 'allowed') {
			window.auth.subscribed = true;
			window.events.$emit('subscribed');

			if (to.name === 'subscribe' || to.name === 'restricted') {
				location.href = '/'; return;
			} else {
				next(); return;
			}
		} else if (data.status === 'restricted') {
			if (to.name === 'restricted' || to.name === 'home') {
				next(); return;
			} else {
				location.href = '/restricted'; return;
			}
		}
	}
}

export default router