<template>
	<div class="block __p __bg">
		<div class="wrapper">
			<header class="block_header">
				<div class="block_title">Видео</div>
			</header>
			
			<div class="streams">
				<div class="streams_row">
					<div class="streams_col" v-for="item in items" :key="item.id">
						<router-link :to="{name: 'video', params: {id: item.id}}" class="streams_item">
							<div class="streams_image">
								<img :src="item.image_ref" alt="">
							</div>
							<div class="streams_content">
								<div class="streams_data">
									<div class="streams_title">
										{{ item.title }}
									</div>
								</div>
							</div>
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';

	export default {
		data() {
			return {
				category: this.$route.query.category_id,
				page: Number(this.$route.query.page) || 1,
				items: [
					{
						id: 1,
						image_ref: "/video/1.jpg",
						title: "League of Legends - Кинематографический 4K трейлер 'Мы - Воины' (2020) - Игра 2020",
						video_file: "/video/1.mp4"
					},
					{
						id: 2,
						image_ref: "/video/2.jpg",
						title: "iPhone и вирус опасны для здоровья. Как избежать неприятных последствий",
						video_file: "/video/2.mp4"
					},
					{
						id: 3,
						image_ref: "/video/3.jpg",
						title: "Escape From Tarkov -- Stream Highlights #20 ДОСТАВКА ЛУТА",
						video_file: "/video/3.mp4"
					},
					{
						id: 7,
						image_ref: "/video/4.jpg",
						title: "DOOM Eternal. Прохождение #1. ЧИСТАЯ КРУТИЗНА",
						video_file: "/video/4.mp4"
					},
				],
				limit: 8,
				count: 0,
				gotData: false
			}
		},
		
		created() {
			//this.initVideos();
		},
		
		methods: {
			initVideos() {
				let params = {
					category_id: this.category,
					limit: this.limit,
					page: this.page
				};
				
				this.axios.get('/api/videos', {params: params})
					.then(({data}) => {
						this.items = data.data;
						this.count = data.count;
						this.gotData = true;
					});
			},
			getTime(time) {
				return moment(time).locale('ru').format('D MMMM Y, HH:mm')
			},
			
			updateData(page) {
				this.page = page;
				
				this.$router.push({
					name: 'videos',
					query: {
						page: page,
						category_id: this.category
					}
				});
			},
			updateCategory(category) {
				this.category = category;
				
				this.updateData(1);
			}
		}
	}
</script>

<style scoped>

</style>