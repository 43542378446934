<template>
	<div>
		<div class="res-tab">
			<div class="res-tab_head">
				<ul class="res-tab_head-list __col-2">
					<li :class="{active: name === active}" v-for="(heading, name) in headings" :key="'heading-' + name">
						<a @click.prevent="changeTab(name)" href="">{{ heading }}</a>
					</li>
					<slot name="custom-heading"></slot>
				</ul>
			</div>
			<div class="res-tab_body">
				<div :class="{active: name === active}" class="res-tab_block" v-for="(heading, name) in headings" :key="'content' + name">
					<slot :name="name"></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['headings', 'activeKey', 'base', 'classNames'],
		
		data() {
			return {
				active: this.activeKey
			}
		},
		
		methods: {
			changeTab(name) {
				this.active = name;
			}
		}
	}
</script>

<style scoped>

</style>