<template>
	<div>
		<div class="video" v-if="$route.params.id == 1">
			<div class="wrapper">
				<h1>League of Legends - Кинематографический 4K трейлер 'Мы - Воины' (2020) - Игра 2020</h1>
				
				<div class="video_file">
					<video controls="controls" style="max-width: 600px; width: 100%; display:block; margin: 0 auto;" src="/video/1.mp4">
					</video>
				</div>
			</div>
		</div>
		<div class="video" v-if="$route.params.id == 2">
			<div class="wrapper">
				<h1>iPhone и вирус опасны для здоровья. Как избежать неприятных последствий</h1>
				
				<div class="video_file">
					<video controls="controls" style="max-width: 600px; width: 100%; display:block; margin: 0 auto;" src="/video/2.mp4">
					</video>
				</div>
			</div>
		</div>
		<div class="video" v-if="$route.params.id == 3">
			<div class="wrapper">
				<h1>Escape From Tarkov -- Stream Highlights #20 ДОСТАВКА ЛУТА</h1>
				
				<div class="video_file">
					<video controls="controls" style="max-width: 600px; width: 100%; display:block; margin: 0 auto;" src="/video/3.mp4">
					</video>
				</div>
			</div>
		</div>
		<div class="video" v-if="$route.params.id == 4">
			<div class="wrapper">
				<h1>DOOM Eternal. Прохождение #1. ЧИСТАЯ КРУТИЗНА</h1>
				
				<div class="video_file">
					<video controls="controls" style="max-width: 600px; width: 100%; display:block; margin: 0 auto;" src="/video/4.mp4">
					</video>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {}
</script>

<style scoped>

</style>