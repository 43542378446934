<template>
	<div>
		<div v-if="value === 'CtWin'">
			<svg class="csgo-icon csgo-icon_blue" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 56.7 56.7">
				<path d="M28.4,0.3C14.1,0.3,3,11.8,3,25.7c0,16.1,4.5,17.8,11.2,17.1c0,0,2.4,13.6,6.6,13.6s8,0,8,0s3.8,0,7.7,0  s6.6-13.6,6.6-13.6c6.6,0.7,11.2-1,11.2-17.1C54.3,11.8,42.8,0.3,28.4,0.3z M14.3,34.5c-3.5-1.1-5.2-5.5-3.8-10  c1.4-4.5,5.3-7.1,8.8-6c3.5,1.1,5.2,5.5,3.8,10C21.8,32.8,17.8,35.6,14.3,34.5z M28.8,41c-2.8,0-5.2,5.9-5.2-0.3c0-0.3,0-0.7,0-1  c0.7-2.8,3.1-7.3,4.9-7.3c2.1,0,5.2,5.2,5.2,7.7c0,0,0,0.3,0.3,0.7C34,46.7,31.2,41,28.8,41z M43.1,34.4c-3.5,1.1-7.4-1.6-8.8-6  c-1.4-4.5,0.4-8.9,3.8-10c3.5-1.1,7.4,1.6,8.8,6C48.3,28.9,46.6,33.3,43.1,34.4z"></path>
			</svg>
		</div>
		<div v-if="value === 'TWin'">
			<svg class="csgo-icon csgo-icon_yellow" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 56.7 56.7">
				<path d="M28.4,0.3C14.1,0.3,3,11.8,3,25.7c0,16.1,4.5,17.8,11.2,17.1c0,0,2.4,13.6,6.6,13.6s8,0,8,0s3.8,0,7.7,0  s6.6-13.6,6.6-13.6c6.6,0.7,11.2-1,11.2-17.1C54.3,11.8,42.8,0.3,28.4,0.3z M14.3,34.5c-3.5-1.1-5.2-5.5-3.8-10  c1.4-4.5,5.3-7.1,8.8-6c3.5,1.1,5.2,5.5,3.8,10C21.8,32.8,17.8,35.6,14.3,34.5z M28.8,41c-2.8,0-5.2,5.9-5.2-0.3c0-0.3,0-0.7,0-1  c0.7-2.8,3.1-7.3,4.9-7.3c2.1,0,5.2,5.2,5.2,7.7c0,0,0,0.3,0.3,0.7C34,46.7,31.2,41,28.8,41z M43.1,34.4c-3.5,1.1-7.4-1.6-8.8-6  c-1.4-4.5,0.4-8.9,3.8-10c3.5-1.1,7.4,1.6,8.8,6C48.3,28.9,46.6,33.3,43.1,34.4z"></path>
			</svg>
		</div>
		<div v-if="value === 'BombDefused'">
			<svg class="csgo-icon csgo-icon_blue" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 56.7 56.7">
				<g id="icon-csgo-defused">
					<path d="M15.9,12.8c-1.4,0.5-1.9-0.6-2.6-1.5C11.8,9,10.2,6.7,8.7,4.4C8.5,4.1,8.3,3.7,8.1,3.3C8.1,3.2,8.2,3.1,8.3,3    c0.4,0.2,0.8,0.3,1.1,0.5c1.8,1.3,3.5,2.9,5.3,4.1c2,1.4,3.6,3.1,4.8,5.3c0.5,1,1.4,1.5,2.3,1.9c3.4,1.6,6.9,0.9,10.4,0.8    c3.9-0.1,7.9-0.7,11.7,0.9c1.3,0.5,2.7,1.2,3.9,2.1c1.8,1.2,3.4,2.6,5.1,3.9c0.8,0.6,1,1.7,0.6,2.6c-0.4,0.9-1.6,1.5-2.4,1    c-1.1-0.6-2.1-1.3-3.1-2c-3-2-6.2-3.7-9.7-3.8c-2.7-0.1-5.4,0.3-8.1,0.6c-2.2,0.2-4.4,0.6-6.5-0.6c-1.1-0.7-1.5,0.3-1.9,1    c-0.2,0.3,0,1.1,0.2,1.5c1.2,1.7,2.4,3.4,3.7,5.2c-0.4-0.1-0.8-0.3-1.4-0.4c0.2,1.5,0.6,2.9,0.7,4.4c0.5,5.1,1.9,9.8,5.1,13.6    c1.2,1.4,2.6,2.6,3.9,3.8c0.6,0.6,1.2,1.2,0.9,2.2c-0.2,1-0.5,2-1.6,2.2c-0.4,0-0.8,0-1.1-0.1c-6.7-4.4-11-10.6-11.8-19.6    c-0.2-2.2-0.3-4.4-0.8-6.5c-0.2-1.1-1-2.2-1.6-3.2c-1.1-1.7-2.1-3.5-4.1-4.1c-1.8-0.5-3.1-1.8-4.5-3.1c-2-1.9-4.1-3.7-6.2-5.6    c-0.3-0.2-0.3-0.8-0.5-1.2c0.4,0,0.8-0.1,1,0c2.8,1.1,5.5,2.3,8.3,3.6c0.4,0.2,0.7,0.8,1,1.3c0.2,0.3,0.4,0.8,0.6,0.8    c0.3,0,0.8-0.2,1-0.5c0.5-0.7,0.8-1.5,1.2-2.2C16,13.1,15.9,12.9,15.9,12.8z"></path>
					<path d="M26.4,22.4c0.8,0.1,1.7,0.3,2.5,0.4c0,0.1,0,0.3,0,0.5c-0.8-0.1-1.7-0.2-2.5-0.3    C26.4,22.8,26.4,22.6,26.4,22.4z"></path>
					<path d="M27.9,25.1c-0.8-0.3-1.7-0.6-2.5-0.9c0.1-0.2,0.1-0.4,0.2-0.5c0.8,0.3,1.6,0.7,2.4,1    C28,24.8,27.9,24.9,27.9,25.1z"></path>
					<path d="M26.9,26.7c-0.8-0.5-1.5-0.9-2.2-1.4c0.1-0.1,0.1-0.3,0.2-0.4c0.8,0.5,1.5,0.9,2.2,1.4    C27,26.4,26.9,26.5,26.9,26.7z"></path>
				</g>
			</svg>
		</div>
		
		<div v-if="value === 'BombExploded'">
			<svg class="csgo-icon csgo-icon_yellow" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 56.7 56.7">
				<polygon points="31.5,0 32.1,20.2 43.4,10.6 37.8,25.6 53.6,22.6 41.8,33 54.3,34.3 42.7,41.1 55.2,53.1 38.1,44.7   40,53.5 30.5,45.4 29.2,52 23.7,45.4 16.5,56.7 17.2,42.2 2.5,43.4 13.6,36.7 5.9,33.9 12.8,30.3 1.5,19.6 17.1,25.6 16.4,11.9   23.3,19.4 "></polygon>
			</svg>
		</div>
		
		<div v-if="value === 'Stopwatch'">
			<svg class="csgo-icon csgo-icon_blue" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 56.7 56.7">
				<g id="icon-csgo-time">
					<g>
						<path d="M28.3,10.7c-12.7,0-23,10.3-23,23s10.3,23,23,23s23-10.3,23-23S41,10.7,28.3,10.7z M28.3,53.2    c-10.7,0-19.5-8.7-19.5-19.5c0-10.7,8.7-19.5,19.5-19.5c10.7,0,19.5,8.7,19.5,19.5C47.8,44.4,39,53.2,28.3,53.2z M11.1,10.9    c-0.5-0.9-0.2-1.9,0.6-2.4l3.1-1.8c0.8-0.5,1.9-0.2,2.4,0.7l1.9,3.3c-2.2,0.9-4.3,2.1-6.1,3.5L11.1,10.9z M43.6,14.2    c-1.8-1.5-3.9-2.7-6.1-3.5l1.9-3.3c0.5-0.9,1.6-1.1,2.4-0.7l3.1,1.8c0.9,0.5,1.1,1.6,0.7,2.4L43.6,14.2z M23,5.4V1.8    c0-1,0.8-1.8,1.8-1.8h7.1c1,0,1.8,0.8,1.8,1.8v3.5c0,1-0.8,1.8-1.8,1.8v2.1c-1.2-0.2-2.3-0.3-3.5-0.3S25.9,9,24.8,9.2V7.1    C23.8,7.1,23,6.4,23,5.4z"></path>
					</g>
					<g>
						<path d="M28.3,38.3c-1.2,0-2.2-1-2.2-2.2V20.7c0-1.2,1-2.2,2.2-2.2s2.2,1,2.2,2.2v15.4C30.5,37.3,29.5,38.3,28.3,38.3    z"></path>
					</g>
					<g>
						<circle cx="28.3" cy="35.2" r="3.5"></circle>
					</g>
				</g>
			</svg>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['value']
	}
</script>

<style scoped>

</style>