import Vue from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
const VueInputMask = require('vue-inputmask').default;

Vue.config.productionTip = false;

Vue.prototype.axios = window.axios = axios;

Vue.config.local = true;

if (location.host.search('localhost') !== -1) {
	axios.defaults.baseURL = 'http://cyber-babilon.roscontent.ru/';
	Vue.prototype.$hostname = 'http://cyber-babilon.roscontent.ru/';
} else {
	axios.defaults.baseURL = '';
	Vue.prototype.$hostname = '';
	Vue.config.local = false;
}

window.events = new Vue();

window.auth = {
	auth: false,
	subscribed: false
};

window.getTimeZoneNum = function () {
	var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
	return parseInt((offset < 0 ? "+" : "-") + ("" + Math.floor(o / 60)).slice(-2));
};

window.twitchApi = {
	headers: {
		'client-id': 'gp762nuuoqcoxypju8c569th9wz7q5',
		'Authorization': 'Bearer 1b64iqhe31g79nx4c0faj8lkgzm820'
	}
}

Vue.use(VueInputMask);
new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
