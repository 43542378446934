<template>
	<div>
		<div class="coaching">
			<div class="wrapper">
				<div class="coaching_logo">
					<img src="/img/upskill_logo.png" alt="">
				</div>
				
				<h1 class="coaching_title">Обучение и тренировки по киберспорту</h1>
				
				<div class="coaching-subscribe">
					<div class="coaching-subscribe_group">
						<label class="coaching-subscribe_label">Укажите интересующую вас дисциплину:</label>
						<select class="coaching-subscribe_select">
							<option>Dota</option>
							<option>CS</option>
						</select>
					</div>
					
					<div class="coaching-subscribe_group">
						<label class="coaching-subscribe_label">Ваша почта:</label>
						<input type="text" class="coaching-subscribe_input">
					</div>
					
					<div class="coaching-subscribe_group">
						<label class="coaching-subscribe_label">Ваш телефон:</label>
						<input type="text" class="coaching-subscribe_input" placeholder="+7 *** *** ** **">
					</div>
					
					<div class="coaching-subscribe_bottom">
						<div class="coaching-subscribe_text">Стоимость подписки</div>
						<div class="coaching-subscribe_price">
							29.<sup>90</sup> ₽
						</div>
						<div class="coaching-subscribe_text-2">
							(Без НДС. Цена указана <br>за 1 день подписки)
						</div>
					</div>
					
					<div class="coaching_button __right __mb0">
						<a href="">Оформить подписку</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		
	}
</script>