<template>
	<div>
		<header-block />
		
		<div class="app">
			<router-view :key="$route.fullPath" />
			
			<footer-block />
		</div>
	</div>
</template>

<script>
	import HeaderBlock from "./components/Layout/Header";
	import FooterBlock from "./components/Layout/Footer";
	
	export default  {
		components: {
			HeaderBlock,
			FooterBlock
		}
	}
</script>

<style lang="less">
	@import "./assets/less/main";
</style>