<template>
	<div class="match-block" v-if="match">
		<div class="wrapper">
			<h1 class="match-block_title" v-text="match.league"></h1>
			
			<p class="match-block_date" v-text="getTime(match.date, match.time)"></p>
			
			<div class="match-block-wrapper">
				<div class="match-block_item match-block_item_orange">
					<div class="match-block_item-bg" style="background-image: url('https://svirtus.cdnvideo.ru/sdlNVLO-8LR2bYodSEAOxFEVz8U=/0x0:200x200/150x150/filters:quality(100)/https://hb.bizmrg.com/esports-core-media/27/27afc4515bd8174b24a8077a3d1610d2.png?m=8899e03340904a438ccc1c36846bdf39');"></div>
					<div class="match-block_item-flag"></div>
					<div class="match-block_item-info match-block_item-info_first">
						<div class="match-block-team">
							<div class="match-block-team_name" v-text="match.home_name"></div>
							<div class="match-block-team_logo">
								<!--<img :src="$hostname + match.home_image_ref" alt="">-->
							</div>
						</div>
						<div class="match-block_item-result" v-text="match.home_score"></div>
					</div>
					<div class="match-block_item-list">
						<div class="match-block-player" v-for="lineup in match.lineups.localteam.player" :key="'home-' + lineup.name">
							<div class="match-block-player_about">
								<div class="match-block-player_about-header">
									<div class="match-block-player_about-flag flag-icon flag-icon-fr"></div>
									<div class="match-block-player_about-nickname" v-text="lineup.name"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="match-block_item match-block_item_gray">
					<div class="match-block_item-bg" style="background-image: url('https://svirtus.cdnvideo.ru/36jFGmb_HDFn7QCk2ZLWWG95W4k=/0x0:280x280/150x150/filters:quality(100)/https://hb.bizmrg.com/esports-core-media/f0/f04711c435a411faa83b75c33eb81e50.png?m=bc95758483090a7d39a2f94142028370');"></div>
					<div class="match-block_item-flag"></div>
					<div class="match-block_item-info match-block_item-info_second">
						<div class="match-block-team">
							<div class="match-block-team_name" v-text="match.away_name"></div>
							<div class="match-block-team_logo">
								<!--<img :src="$hostname + match.away_image_ref" alt="Fnatic">-->
							</div>
						</div>
						<div class="match-block_item-result" v-text="match.away_score"></div>
					</div>
					<div class="match-block_item-list">
						<div class="match-block-player" v-for="lineup in match.lineups.awayteam.player" :key="'away-' + lineup.name">
							<div class="match-block-player_about">
								<div class="match-block-player_about-header">
									<div class="match-block-player_about-flag flag-icon flag-icon-se"></div>
									<div class="match-block-player_about-nickname" v-text="lineup.name"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<!--<div class="statistic">
				<table class="statistic_table">
					<thead>
						<tr>
							<th>Герой</th>
							<th>Игрок</th>
							<th>У</th>
							<th>С</th>
							<th>П</th>
						</tr>
					</thead>
				</table>
				
				<div class="statistic_header">
					<span class="statistic_color-1">Свет:</span>
					Evil Geniuses
				</div>
				
				<table class="statistic_table">
					<tbody>
						<tr>
							<td>
								<div class="statistic_image">
									<img src="/img/3.png" alt="">
									<div class="statistic_lvl">20</div>
								</div>
							</td>
							<td>
								<div class="statistic_name">Arteezy</div>
							</td>
							<td>
								<div class="statistic_value">2</div>
							</td>
							<td>
								<div class="statistic_value">2</div>
							</td>
							<td>
								<div class="statistic_value">2</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="statistic_image">
									<img src="/img/3.png" alt="">
									<div class="statistic_lvl">20</div>
								</div>
							</td>
							<td>
								<div class="statistic_name">Arteezy</div>
							</td>
							<td>
								<div class="statistic_value">2</div>
							</td>
							<td>
								<div class="statistic_value">2</div>
							</td>
							<td>
								<div class="statistic_value">2</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="statistic_image">
									<img src="/img/3.png" alt="">
									<div class="statistic_lvl">20</div>
								</div>
							</td>
							<td>
								<div class="statistic_name">Arteezy</div>
							</td>
							<td>
								<div class="statistic_value">2</div>
							</td>
							<td>
								<div class="statistic_value">2</div>
							</td>
							<td>
								<div class="statistic_value">2</div>
							</td>
						</tr>
					</tbody>
				</table>
				
				<div class="statistic_header">
					<span class="statistic_color-2">Свет:</span>
					Evil Geniuses
				</div>
				
				<table class="statistic_table _2">
					<tbody>
						<tr>
							<td>
								<div class="statistic_image">
									<img src="/img/3.png" alt="">
									<div class="statistic_lvl">20</div>
								</div>
							</td>
							<td>
								<div class="statistic_name">Arteezy</div>
							</td>
							<td>
								<div class="statistic_value">2</div>
							</td>
							<td>
								<div class="statistic_value">2</div>
							</td>
							<td>
								<div class="statistic_value">2</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="statistic_image">
									<img src="/img/3.png" alt="">
									<div class="statistic_lvl">20</div>
								</div>
							</td>
							<td>
								<div class="statistic_name">Arteezy</div>
							</td>
							<td>
								<div class="statistic_value">2</div>
							</td>
							<td>
								<div class="statistic_value">2</div>
							</td>
							<td>
								<div class="statistic_value">2</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="statistic_image">
									<img src="/img/3.png" alt="">
									<div class="statistic_lvl">20</div>
								</div>
							</td>
							<td>
								<div class="statistic_name">Arteezy</div>
							</td>
							<td>
								<div class="statistic_value">2</div>
							</td>
							<td>
								<div class="statistic_value">2</div>
							</td>
							<td>
								<div class="statistic_value">2</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>-->
			
			<tab :headings="tabKeys" :active-key="0">
				<template v-for="(csGoStatistic, csGoStatisticKey) in csGoStatistics" :slot="csGoStatisticKey">
					<div class="statistic-dota2__content">
						<header class="flex-box justify-content--center align-items--stretch statistic-dota2__header">
							<div class="flex-box align-items--stretch align-self--center justify-content--flex-end statistic-dota2__team">
								<div class="margin-right--20 align-self--center text-align--right">
									<strong class="inverse-color--gray-01" v-if="isWinner(csGoStatistic, 'localteam') === '<>'"></strong>
									<strong class="color--brand-orange" v-else-if="isWinner(csGoStatistic, 'localteam')">Победа</strong>
									<strong class="inverse-color--gray-01" v-else-if="!isWinner(csGoStatistic, 'localteam')">Поражение</strong>
									<h4 v-text="match.home_name"></h4>
								</div>
							</div>
							<div class="align-self--center text-align--center">
								<strong class="inverse-color--gray-01" v-if="!isWinner(csGoStatistic, 'localteam') && !isWinner(csGoStatistic, 'awayteam')">Ничья</strong>
								
								<div class="align-self--center text-align--center">
									<div class="statistic-dota2__match-info">
										<div class="statistic-dota2__score">
											<strong class="match-statistics__score-round"
													:class="[isWinner(csGoStatistic, 'localteam') ? '__color-1' : '__color-2']"
													v-text="csGoStatistic.scores['localteam'].total"></strong>
											
											<svg style="margin: 0 5px;" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 5 12" width="5">
												<g fill="#8a8a8a" id="icon-colon" fill-rule="evenodd">
													<path d="M2.5 5C1.1192881 5 0 3.8807119 0 2.5S1.1192881 0 2.5 0 5 1.1192881 5 2.5 3.8807119 5 2.5 5zm0 7C1.1192881 12 0 10.8807119 0 9.5S1.1192881 7 2.5 7 5 8.1192881 5 9.5 3.8807119 12 2.5 12z"></path>
												</g>
											</svg>
											
											<strong class="match-statistics__score-round"
													:class="[isWinner(csGoStatistic, 'awayteam') ? '__color-1' : '__color-2']"
													v-text="csGoStatistic.scores['awayteam'].total"></strong>
										</div>
										<div class="statistic-dota2__time" v-text="csGoStatistic.name"></div>
									</div>
								</div>
							</div>
							<div class="flex-box align-items--stretch align-self--center statistic-dota2__team">
								<div class="margin-left--20 align-self--center">
									<strong class="inverse-color--gray-01" v-if="isWinner(csGoStatistic, 'awayteam') === '<>'"></strong>
									<strong class="color--brand-orange" v-else-if="isWinner(csGoStatistic, 'awayteam')">Победа</strong>
									<strong class="inverse-color--gray-01" v-else-if="!isWinner(csGoStatistic, 'awayteam')">Поражение</strong>
									<h4 v-text="match.away_name"></h4>
								</div>
							</div>
						</header>
						
						<div class="match-statistics" v-if="csGoStatistic.stats">
							<div class="csgo-stast">
								<div class="csgo-stast__team csgo-stast__team_top">
									<div class="icon--container icon--container-24 margin-right--10">
										
									</div>
									
									<div class="csgo-stast__round" v-for="(round, key) in csGoStatistic.rounds.firsthalf.round" :key="'csgo-stast-home-' + key">
										<div>
											<icons v-if="round.winner === 't'" :value="round.result"></icons>
										</div>
									</div>
									<div class="csgo-stast__round" v-for="(round, key) in csGoStatistic.rounds.secondhalf.round" :key="'csgo-stast-home2-' + key">
										<div>
											<icons v-if="round.winner === 'ct'" :value="round.result"></icons>
										</div>
									</div>
								</div>
								
								<div class="csgo-stast__team csgo-stast__team_bottom">
									<div class="icon--container icon--container-24 margin-right--10">
									
									</div>
									
									<div class="csgo-stast__round" v-for="(round, key) in csGoStatistic.rounds.firsthalf.round" :key="'csgo-stast-away-' + key">
										<div>
											<icons v-if="round.winner === 'ct'" :value="round.result"></icons>
										</div>
									</div>
									<div class="csgo-stast__round" v-for="(round, key) in csGoStatistic.rounds.secondhalf.round" :key="'csgo-stast-away2-' + key">
										<div>
											<icons v-if="round.winner === 't'" :value="round.result"></icons>
										</div>
									</div>
								</div>
							</div>
							
							<div class="csgo-players-stats">
								<div class="csgo-players-stats__team">
									<div class="csgo-players-stats__team-info">
										<div class="csgo-players-stats__team-info__item">
											<div class="flex-box">
												<div class="icon--container icon--container-18 margin-right--10" style='background-image: url("");'></div>
												<div>{{ match.home_name }}</div>
											</div>
										</div>
										<div class="csgo-players-stats__team-info__item">K</div>
										<div class="csgo-players-stats__team-info__item">D</div>
										<div class="csgo-players-stats__team-info__item">A</div>
									</div>
									<div class="csgo-players-stats__item" v-for="(player) in csGoStatistic.stats.localteam.player" :key="player.id">
										<div class="csgo-players-stats__col">
											<div class="csgo-players-stats__col-info">
												<div class="match-duel-player__photo margin-right--16">
													<img src="/img/gamer.svg" alt="">
												</div>
												<div class="match-duel-player__about">
													<div class="match-duel-player__about-header">
														<div class="match-duel-player__about-nickname margin-right--8">{{ player.name }}</div>
														<div
														class="match-duel-player__about-flag flag-icon flag-icon-fr"></div>
													</div>
													<div class="match-duel-player__about-name"></div>
												</div>
											</div>
										</div>
										<div class="csgo-players-stats__col" data-type="kill">{{ player.kills }}</div>
										<div class="csgo-players-stats__col" data-type="death">{{ player.death }}</div>
										<div class="csgo-players-stats__col">{{ player.assists }}</div>
									</div>
								</div>
								
								<div class="csgo-players-stats__team">
									<div class="csgo-players-stats__team-info">
										<div class="csgo-players-stats__team-info__item">
											<div class="flex-box">
												<div class="icon--container icon--container-18 margin-right--10" style='background-image: url("");'></div>
												<div>{{ match.away_name }}</div>
											</div>
										</div>
										<div class="csgo-players-stats__team-info__item">K</div>
										<div class="csgo-players-stats__team-info__item">D</div>
										<div class="csgo-players-stats__team-info__item">A</div>
									</div>
									<div class="csgo-players-stats__item" v-for="(player) in csGoStatistic.stats.awayteam.player" :key="player.id">
										<div class="csgo-players-stats__col">
											<div class="csgo-players-stats__col-info">
												<div class="match-duel-player__photo margin-right--16">
													<img src="/img/gamer.svg" alt="">
												</div>
												<div class="match-duel-player__about">
													<div class="match-duel-player__about-header">
														<div class="match-duel-player__about-nickname margin-right--8">{{ player.name }}</div>
														<div
														class="match-duel-player__about-flag flag-icon flag-icon-fr"></div>
													</div>
													<div class="match-duel-player__about-name"></div>
												</div>
											</div>
										</div>
										<div class="csgo-players-stats__col" data-type="kill">{{ player.kills }}</div>
										<div class="csgo-players-stats__col" data-type="death">{{ player.death }}</div>
										<div class="csgo-players-stats__col">{{ player.assists }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</tab>
		</div>
	</div>
	<div v-else-if="loading" class="loading" style="margin-top: 10px;"></div>
</template>

<script>
	import moment from 'moment';
	import Tab from "../components/Tab/Tab";

	import Icons from "../components/Match/Scgo/Stat/Icons";

	export default {
		components: {
			Icons,
			Tab
		},

		data() {
			return {
				match: null,
				id: this.$route.params.id,
				sport: this.$route.params.sport,
				day_diff: this.$route.query.day_diff,
				tabKeys: [],
				csGoStatistics: null,
				loading: true
			}
		},

		created() {
			this.axios.get('/goalserve/esport/events?type=' + this.sport + '&day_diff=' + this.day_diff)
				.then(({data}) => {
					this.loading = false;
					
					this.match = data.res.filter(item => item.id == this.id)[0];
	
					this.csGoStatistics = this.match.maps.map;
					this.csGoStatistics.forEach(item => this.tabKeys.push(item.name));
				});
		},

		methods: {
			getTime(date, time) {
				let method = window.getTimeZoneNum() >= 0 ? 'add' : 'subtract';

				date = date.split('.');
				date = date[2] + '-' + date[1] + '-' + date[0];

				return moment(date + 'T' + time)[method](window.getTimeZoneNum(), 'hours').format('DD.MM.Y HH:mm');
			},
			isWinner(statistic, team) {
				let opponent = team === 'localteam' ? 'awayteam' : 'localteam';
				
				let team1 = Number(statistic.scores[team]['total']);
				let team2 = Number(statistic.scores[opponent]['total']);
				
				if (team1 === team2) {
					return '<>';
				}
				
				return team1 > team2;
			}
		},
		computed: {
			
		}
	}
</script>

<style scoped>

</style>