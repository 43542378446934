<template>
	<div>
		<div class="coaching">
			<div class="wrapper">
				<div class="coaching_logo">
					<img src="/img/upskill_logo.png" alt="">
				</div>
				
				<div class="coaching-courses">
					<div class="coaching-courses_user">
						<img src="/img/question.svg" alt="">
						<span>UserName</span>
					</div>
					
					<div class="coaching-courses_tab">
						<div class="coaching-courses_tab-header">
							<a @click.prevent="changeBlock('courses')" href="" :class="{active: activeBlock === 'courses'}">Мои курсы</a>
							<a @click.prevent="changeBlock('promocode')" href="" :class="{active: activeBlock === 'promocode'}">Промокод</a>
						</div>
						
						<div class="coaching-courses_blocks">
							<div v-if="activeBlock === 'courses'" class="coaching-courses_block">
								<div class="coaching-courses_courses" v-for="course in courses" :key="course.id">
									<div class="coaching-courses_course">
										<div class="coaching-courses_course-top">
											<img :src="'https://upskill.gg/' + course.icon" alt="">
											<span></span>
										</div>
										<div class="coaching-courses_course-block-2">
											<div v-text="course.title"></div>
										</div>
										<div class="coaching-courses_course-block-3">
											Количество занятий: {{ course.lessos_num }}
										</div>
										<div class="coaching-courses_course-block-3">
											{{ getDateTime(course.expiration) }}
										</div>
										<div class="coaching-courses_course-block-3">
											Длительность: {{ course.duration }} ч.
										</div>
										<div class="coaching-courses_course-block-4">
											<a href="" class="coaching-courses_button">Подключиться</a>
										</div>
									</div>
									<div class="coaching-courses_description">
										<h3 class="coaching-courses_title">Описание</h3>
										
										<div class="coaching-courses_d" v-html="course.description"></div>
									</div>
								</div>
							</div>
							<div v-if="activeBlock === 'promocode'" class="coaching-courses_block">
								<div class="coaching-courses_text">
									Обладатели подписки киберспортала получают промокод со скидкой 5% <br>
									на все тренировки платформы Upskill. (Вводить при оплате тренировки)
								</div>
								
								<div class="coaching-courses_row">
									<span>Промокод</span>
									<input type="text">
								</div>
								
								<a href="" class="coaching-courses_button">Заказать тренировку!</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import $ from 'jquery';
	
	export default {
		data() {
			return {
				activeBlock: 'courses',
				courses: null,
				showDescriptionBlock: false
			}
		},
		created() {
			this.axios({url: 'https://upskill.gg/api?API_KEY=YAsGT1TSA9GOwHsukMyw6nuM3qlMoFx8'})
				.then(({data}) => {
					this.courses = data;
				});
			
			this.showDescription();
			
			window.addEventListener("resize", this.showDescription);
			
			$(document).on('click', '.coaching-courses_title', function () {
				if ($(window).width() > 767) return;
				
				$(this).next().slideToggle(300, function () {
					$(this).removeAttr('style');
					$(this).toggleClass('active')
				});
				$(this).toggleClass('active');
			});
		},
		methods: {
			changeBlock(block) {
				this.activeBlock = block;
			},
			getDateTime(datetime) {
				return moment(datetime).locale('ru').format('D MMMM Y, HH:mm')
			},
			showDescription() {
				
			}
		}
	}
</script>