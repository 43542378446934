<template>
	<div>
		<div class="match">
			<div class="wrapper">
				<h2 class="match_heading">Результаты</h2>
				
				<links v-if="matches" :data="matches"></links>
				<div v-else class="loading"></div>
			</div>
		</div>
		
		<!--
		<div class="card" v-if="cards">
			<div class="wrapper">
				<div class="card_row">
					<div class="card_col" v-for="card in cards.data" :key="card.id">
						<a href="" class="card_item" :style="'background-image: url(' + card.image_ref + ')'">
							<div class="card_content">
								<div class="card_tag">Статьи</div>
								<div class="card_text">{{card.title}}</div>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
		-->
		
		<div class="block __bg __p" v-if="news">
			<div class="wrapper">
				<header class="block_header">
					<div class="block_title">НОВОСТИ</div>
					<router-link :to="{name: 'news'}" class="block_link">Смотреть все</router-link>
				</header>
				
				<base-news :data="news.data" />
			</div>
		</div>
		
		<div class="block __p" v-if="articles">
			<div class="wrapper">
				<header class="block_header">
					<div class="block_title">СТАТЬИ</div>
					<router-link :to="{name: 'articles'}" class="block_link">Смотреть все</router-link>
				</header>
				
				<base-articles :data="articles.data" />
			</div>
		</div>
		
		<div class="block __p __bg" v-if="users">
			<div class="wrapper">
				<header class="block_header">
					<div class="block_title">СТРИМЫ</div>
					<router-link :to="{name: 'streams'}" class="block_link">Смотреть все</router-link>
				</header>
				
				<div class="streams">
					<streamers :users="users"></streamers>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import _ from "underscore";
	
	import BaseNews from "../components/News/BaseNews";
	import BaseArticles from "../components/Articles/BaseArticles";
	import Links from "../components/Events/Links";
	import Streamers from "../components/Twitch/Streamers";
	
	export default {
		components: {
			BaseNews,
			BaseArticles,
			Links,
			Streamers
		},
		
		data() {
			return  {
				news: null,
				articles: null,
				matches: null,
				cards: null,
				streams: null,
				users: null
			}
		},
		
		created() {
			this.initNews();
			this.initArticles();
			this.initMatches();
			
			this.axios.get('/api/articles??page=2&sport=overwatch&limit=3')
				.then(({data}) => {
					this.cards = data;
				});


			this.axios.get('/links').then(({data}) => {
				this.streams = data.res ? data.res.data : null;

				this.getStreamersInfo();
			});
		},
		
		methods: {
			initNews() {
				this.axios.get('/api/news?page=1&sport=overwatch')
					.then(({data}) => {
						this.news = data;
					});
			},
			initArticles() {
				this.axios.get('/api/articles?page=1&sport=overwatch')
					.then(({data}) => {
						this.articles = data;
					});
			},
			initMatches() {
				this.axios.get('/goalserve/esport/events?type=CS GO')
					.then(({data}) => {
						this.matches = _.groupBy(data.res.slice(0, 10), 'league');
					});
			},
			getStreamersInfo() {
				let users = '';

				this.streams.forEach((item) => {
					users += 'user_login=' + item.url + '&';
				});

				this.axios.get('https://api.twitch.tv/helix/streams?' + users, twitchApi).then(({data}) => {
					this.users = data.data.slice(0, 3);
				});
			}
		}
	}
	//470x265
</script>

<style scoped>

</style>
