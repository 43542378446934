<template>
	<div class="streams_row">
		<div class="streams_col" v-for="user in users" :key="user.id">
			<router-link :to="{name: 'stream', params: {id: user.user_id}}" class="streams_item">
				<div class="streams_image">
					<img :src="getThumbnailUrl(user.thumbnail_url)" alt="">
					
					<div class="streams_online">В ЭФИРЕ</div>
				</div>
				<div class="streams_content">
					<div class="streams_data">
						<div class="streams_title">{{ user.user_name }}</div>
						<div class="streams_text">{{ user.game_name }}</div>
					</div>
				</div>
			</router-link>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['users', 'streams'],
		
		methods: {
			getThumbnailUrl(url) {
				return url.replace('{width}', '470').replace('{height}', '265');
			},
		}
	}
</script>

<style scoped>

</style>