<template>
	<div class="block __p">
		<div class="wrapper">
			<header class="block_header">
				<div class="block_title">НОВОСТИ</div>
			</header>
			
			<!--<div class="nav __base">
				<sport-categories @update="updateSport" :sport="sport"></sport-categories>
			</div>-->
			
			<base-news :data="items" />
			
			<paginate v-if="count" @page-changed="updateData" :total="count" :limit="limit"></paginate>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	
	import BaseNews from "../components/News/BaseNews";
	import SportCategories from "../components/News/SportCategories";
	import Paginate from "../components/Pagination/Paginate";
	
	export default {
		components: {
			BaseNews,
			SportCategories,
			Paginate
		},
		
		data() {
			return {
				sport: this.$route.query.sport,
				page: Number(this.$route.query.page) || 1,
				items: [],
				limit: 9,
				count: 0,
				gotData: false
			}
		},
		
		mounted() {
			
		},
		
		created() {
			this.initNews();
		},
		
		methods: {
			initNews() {
				let params = {
					sport: 'cyber',
					limit: this.limit,
					page: this.page
				};
				
				this.axios.get('/api/news', {params: params})
					.then(({data}) => {
						this.items = data.data;
						this.count = data.count;
						this.gotData = true;
					});
			},
			getTime(time) {
				return moment(time).locale('ru').format('D MMMM Y, HH:mm')
			},
			
			updateData(page) {
				this.page = page;
				
				this.$router.push({
					name: 'news',
					query: {
						page: page,
						sport: this.sport
					}
				});
			},
			updateSport(sport) {
				this.sport = sport;
				
				this.updateData(1);
			}
		}
	}
</script>