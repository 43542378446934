<template>
	<div>
		<div class="subscribe-form">
			<div class="subscribe-form_ico">
				<img src="/img/ico-phone.svg" width="55" alt="">
			</div>
			<div class="subscribe-form_text">Подписка на Кибер портал - <span style="color: red">стоимость 1 TJS/день</span>.</div>
			
			<a href="" @click.prevent="subscribe" class="subscribe-form_button">Подписаться</a>
			
			<div v-if="loading" class="subscribe-form_spinner">
				<img src="/img/spinner.gif" width="44" alt="">
			</div>
			
			<div class="subscribe-form_text-2">
				Или наберите со своего мобильного устройства (активация *1191*9#, отключение *1191*9*0#)
			</div>
			
			<div v-if="error" class="subscribe-form_error">
				Ошибка подписки. Попробуйте еще раз
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				error: null,
				loading: false,
				login: null,
			}
		},
		
		methods: {
			subscribe() {
				if (this.loading) return;
				
				this.loading = true;
				this.error = false;
				
				this.axios({url: '/a1/subscribe',  baseURL: '/'}).then(({data}) => {
					if (data.status === 'subscribed') {
						location.href = '/';
					} else {
						this.error = true;
						this.loading = false;
					}
				});
			},
		},
	}
</script>

<style scoped>

</style>