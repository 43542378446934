<template>
	<div class="articles">
		<div class="articles_row">
			<div class="articles_col" v-for="article in articles" :key="article.id">
				<div class="articles_item">
					<router-link :to="{name: 'article', params: {id: article.id}}" class="articles_image">
						<img :src="article.image_ref" alt="">
					</router-link>
					<div class="articles_content">
						<a href="" class="articles_tag">Киберспорт</a>
						<router-link :to="{name: 'article', params: {id: article.id}}" class="articles_title">{{ article.title }}</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['data'],
		
		computed: {
			articles() {
				return this.data;
			}
		}
	}
</script>

<style scoped>

</style>